<template>
  <div class="main-content q-pa-md">
    <Mural :breadcrumbs="breadcrumbs" route="wiki" :query="{}" />
    <div class="content-new-training flex row col-12 lesson-content">
      <div class="content-form-training col-12">
        <form>
          <div class="col-12 flex row q-pa-sm">
            <div class="col-12 thumb-training q-mt-sm">
              <q-img
                height="150px"
                :src="imageUrl"
                fit="cover"
                style="background-color: #e5e5e5; border-radius: 5px"
              />
            </div>
          </div>

          <div class="col-12 thumb-training q-mt-sm">
            <q-item>
              <q-item-section>
                <q-item-label>{{ wiki.title }}</q-item-label>
                <q-item-label caption
                  >Categoria: {{ wiki.category_name }}</q-item-label
                >
                <q-item-label caption
                  >Criador por <b>{{ wiki.name_user }}</b> {{ wiki.created_at }}
                </q-item-label>
              </q-item-section>
              <div class="row">
                <q-chip clickable v-for="tag in wiki.tag" :key="tag.name">
                  {{ tag.name }}
                </q-chip>
              </div>
              <q-item-section side top>
                <div class="text-orange">
                  <!--<q-icon name="star" :key="evaluation" v-for="evaluation in wiki.evaluation" />-->
                  <q-rating
                    v-model="wiki.evaluation"
                    max="5"
                    size="25px"
                    color="yellow"
                    icon="star_border"
                    icon-selected="star"
                    icon-half="star_half"
                    readonly
                  >
                    <template v-slot:tip-1>
                      <q-tooltip>{{ wiki.evaluation }}</q-tooltip>
                    </template>
                    <template v-slot:tip-2>
                      <q-tooltip>{{ wiki.evaluation }}</q-tooltip>
                    </template>
                    <template v-slot:tip-3>
                      <q-tooltip>{{ wiki.evaluation }}</q-tooltip>
                    </template>
                    <template v-slot:tip-4>
                      <q-tooltip>{{ wiki.evaluation }}</q-tooltip>
                    </template>
                    <template v-slot:tip-5>
                      <q-tooltip>{{ wiki.evaluation }}</q-tooltip>
                    </template>
                  </q-rating>
                </div>
              </q-item-section>
            </q-item>
            <q-separator spaced inset />
          </div>
          <div class="col-12 thumb-training">
            <q-item>
              <q-item-section>
                <q-item-label>
                  <q-icon name="attach_file" size="sm" class="icon" />
                  Anexos</q-item-label
                >
              </q-item-section>
            </q-item>
            <q-item>
              <div
                class="col-2"
                v-for="(anexo, index) in wiki.anexos"
                :key="index"
              >
                <q-item
                  class="q-uploader__file-header q-ma-sm row flex-center no-wrap card-attachment"
                >
                  <q-item-section avatar>
                    <q-item-label>
                      <q-icon
                        name="description"
                        style="color: var(--q-secundary)"
                        class="material-icons-outlined"
                        size="lg"
                      />
                    </q-item-label>
                  </q-item-section>

                  <q-item-section class="q-uploader__file-header-content col">
                    <q-item-label
                      class="q-uploader__title full-width"
                      caption
                      lines="2"
                    >
                      <a
                        :href="url_aws_bucket + anexo.path"
                        download
                        target="_blank"
                        >{{ anexo.name }}</a
                      >
                    </q-item-label>
                  </q-item-section>
                </q-item>
              </div>
            </q-item>
            <q-separator spaced inset />
          </div>

          <div class="col-12 thumb-training">
            <q-item>
              <q-item-section>
                <q-item-label>
                  <q-icon name="format_indent_increase" size="sm" />
                  Resumo</q-item-label
                >
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section>
                <q-item-label> {{ wiki.description }}</q-item-label>
              </q-item-section>
            </q-item>
            <q-separator spaced inset />
          </div>
          <div class="thumb-training flex column col-12">
            <q-item>
              <q-item-section>
                <q-item-label>
                  <q-icon name="format_align_left" size="sm" />
                  Conteúdo</q-item-label
                >
              </q-item-section>
            </q-item>
            <div
              class="content-wiki"
              v-for="(content, index) in wiki.content"
              :key="index"
            >
              <section>
                <q-expansion-item
                  switch-toggle-side
                  expand-separator
                  :label="content.section_Title"
                >
                  <q-card>
                    <q-card-section>
                      <q-item-label
                        v-html="getConvertendHtml(content.section_Content)"
                      />
                    </q-card-section>
                  </q-card>
                </q-expansion-item>
              </section>
            </div>
            <!-- <q-item>
              <q-item-section>
                <q-item-label v-html="wiki.content" />
              </q-item-section>
            </q-item> -->
            <q-separator spaced inset />
          </div>
          <div class="col-12 thumb-training" v-show="gameInWiki">
            <q-item>
              <q-item-section>
                <q-item-label>
                  <q-icon name="format_align_left" size="sm" /> Conteúdo
                  Interativo</q-item-label
                >
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section>
                Esse material incentiva seu conhecimento a se envolver
                ativamente com o conteúdo. Você poderá interagir, receber
                informações e obter percepções sobre suas necessidades.
              </q-item-section>
            </q-item>
            <q-item>
              <q-item class="card-attachment">
                <q-btn
                  flat
                  no-caps
                  target="_blank"
                  :to="{
                    name: 'game-intro',
                    query: {
                      gameId: wiki.gameId,
                    },
                  }"
                  :outline="true"
                  unelevated
                >
                  <interactivity color="#FE4E64" class="q-mr-sm" />
                  <span class="name_content">{{ wiki.name_game }}</span>
                </q-btn>
              </q-item>
            </q-item>
            <q-separator spaced inset />
          </div>
          <div class="col-12 flex justify-center centers q-mt-lg">
            <q-btn
              color="default-pink"
              class="q-ml-sm"
              text-color="default-pink"
              rounded
              no-caps
              :outline="true"
              @click="cardRating = true"
              unelevated
            >
              <q-icon name="grade" class="material-icons-outlined" size="sm" />
              <p style="margin: 0px 10px">Avaliar</p>
            </q-btn>
            <q-btn
              color="default-pink"
              class="q-ml-sm"
              v-show="!concludedWiki"
              text-color="default-pink"
              rounded
              no-caps
              @click="finishRead()"
              :outline="true"
              unelevated
            >
              <q-icon
                name="checklist"
                class="material-icons-outlined"
                size="sm"
              />
              <p style="margin: 0px 10px">Marcar como Lida</p>
            </q-btn>
          </div>
          <br />
        </form>
      </div>
    </div>
    <q-inner-loading
      :showing="loading"
      label="Por favor aguarde..."
      label-class="text-teal"
      label-style="font-size: 1.1em"
    >
      <div class="collumn justify-center q-my-md">
        <div style="height: auto; width: 50px; margin: auto">
          <LoadingIcon />
        </div>
        <label>Por favor aguarde...</label>
      </div>
    </q-inner-loading>
  </div>

  <q-dialog v-model="cardRating">
    <q-card class="my-card">
      <q-card-section>
        <q-rating
          v-model="starsWiki"
          @click="sendRatingWiki()"
          v-close-popup
          :max="5"
          size="64px"
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import Mural from "@/components/Mural.vue";
import DefaultBtn from "@/components/shared/buttons/DefaultBtn.vue";
import InputNumber from "@/components/shared/inputs/InputNumber.vue";

/* Icons */
import Interactivity from "@/assets/icons/custom/Interactivity.vue";
import LoadingIcon from "@/components/LoadingIcon.vue";

import WikiDataServices from "@/services/WikiDataServices";
import { ref, reactive, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { ENV } from "@/utils/env";
import { date, useQuasar } from "quasar";

export default {
  name: "WikiExplorer",
  components: {
    Mural,
    DefaultBtn,
    InputNumber,
    LoadingIcon,
    Interactivity,
  },
  setup() {
    let breadcrumbs = ref(["Wiki"]);
    const imageUrl = ref("");
    let cardRating = ref(false);
    let starsWiki = ref(0);
    let loading = ref(false);
    let gameInWiki = ref(false);
    let route = new useRoute();
    let router = new useRouter();
    let url_aws_bucket = ENV.URL_AWS_BUCKET;
    let wikiDataServices = new WikiDataServices();
    let concludedWiki = ref(false);
    let $q = new useQuasar();
    let wikiId = route.query.wikiId;
    let wiki = reactive({
      banner: null,
      title: "",
      description: "",
      category: [],
      dependency: null,
      name_user: "",
      evaluation: 0,
      category_name: "",
      created_at: "",
      content: [],
      gameId: 0,
      tag: [],
      group: [],
      files: [],
      anexos: [],
      name_game: "",
    });

    onMounted(async () => {
      await GetWiki(route.query.wikiId);
    });

    function alertNotify(message) {
      $q.notify({
        message: message,
        color: "red-9",
        position: "top",
      });
    }

    async function GetWiki(wikiId) {
      if (wikiId == null) return null;

      loading.value = true;
      await wikiDataServices
        .read(wikiId)
        .then((response) => {
          breadcrumbs.value.push(response.data.title);
          wiki.name_user = response.data.name_user;
          wiki.category_name = response.data.category_name;
          wiki.created_at = date.formatDate(
            response.data.created_at,
            "DD/MM/YYYY H:mm"
          );
          wiki.title = response.data.title;
          wiki.description = response.data.description;
          wiki.category = response.data.category;
          wiki.dependency =
            response.data.dependency == 0 ? null : response.data.dependency;
          wiki.content = response.data.contents;
          wiki.tag = response.data.tag;
          imageUrl.value =
            response.data.banner == null
              ? null
              : url_aws_bucket + response.data.banner;
          wiki.anexos = response.data.anexos;
          wiki.evaluation = response.data.evaluation;
          wiki.gameId = response.data.game_id ?? null;
          gameInWiki.value = [null, 0, undefined].includes(
            response.data.game_id
          )
            ? false
            : true;
          concludedWiki.value = response.data.concluded;
          starsWiki.value = response.data.evaluation_user;
          wiki.name_game = response.data.name_game;
          loading.value = false;

          // document.getElementById("testEditor").innerHTML = "I have changed!";
        })
        .catch((error) => {
          alertNotify(error.response.data.toString());
          loading.value = false;
          //router.push({ name: "wiki" });
        });
    }

    async function sendRatingWiki() {
      let evaluation = starsWiki.value;
      loading.value = true;
      await wikiDataServices.evaluation(wikiId, evaluation).catch((error) => {
        alertNotify(error.response.data.toString());
        loading.value = false;
      });

      loading.value = false;
      cardRating.value = false;
    }

    async function finishRead() {
      loading.value = true;
      await wikiDataServices
        .finish(wikiId)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          alertNotify(error.response.data.toString());
          loading.value = false;
        });

      loading.value = false;
      concludedWiki.value = true;
    }

    function getConvertendHtml(value) {
      value = value.replaceAll("&lt;iframe", "<iframe");
      value = value.replaceAll("&gt;&lt;/iframe&gt;", "></iframe>");
      return value;
    }

    return {
      breadcrumbs,
      imageUrl,
      cardRating,
      starsWiki,
      sendRatingWiki,
      finishRead,
      concludedWiki,
      gameInWiki,
      wiki,
      loading,
      url_aws_bucket,
      getConvertendHtml,
    };
  },
};
</script>

<style lang="scss" scoped>
.main-content {
  width: 100%;
  height: 100%;

  .lesson-content {
    width: 100%;
    height: 75vh;
    overflow-y: auto;
  }
}

.card-attachment,
.q-btn .card-attachment {
  background: #fdfdfd;
  border: 0.2px solid #dfdfdf;
  box-sizing: border-box;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 15px 10px;
}

.main-content {
  width: 100%;
  height: 100%;

  .title-form {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    opacity: 0.45;
  }

  .content-new-training {
    height: 68vh;

    .content-thumb-training {
      height: 100%;
      .thumb-training {
        border-radius: 10px;

        .q-img {
          border-radius: 10px;
        }
      }
      .btn-thumb-training {
        .q-btn {
          text-transform: none !important;
          border-radius: 8px;
        }
      }
      .label-title-menus {
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color: #686868;
      }
    }
    .content-form-training {
      height: 100%;

      .form-input {
        .title-input-form {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: #000000;
          opacity: 0.45;
        }
      }
    }
  }

  .name_content {
    font-family: "Montserrat";
  }

  @media (min-width: 800px) {
    flex-direction: row;
  }
}
</style>
