<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 30 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.625 17.25C25.6875 17.25 24.8906 17.625 24.2812 18.1875L20.625 16.0781C20.8594 15.5156 21 14.9062 21 14.25C21 11.3906 18.6094 9 15.75 9C15.3281 9 14.9531 9.09375 14.5781 9.14062L13.0781 5.90625C13.7812 5.29688 14.25 4.40625 14.25 3.42188C14.25 1.54688 12.7031 0.046875 10.875 0.046875C9 0.046875 7.5 1.54688 7.5 3.42188C7.5 5.25 9 6.79688 10.875 6.79688C10.875 6.79688 10.9219 6.75 10.9688 6.75L12.5625 10.125C11.2969 11.0625 10.5 12.5625 10.5 14.25C10.5 17.1562 12.8438 19.5 15.75 19.5C17.1562 19.5 18.4688 18.9375 19.4062 18L23.2969 20.2031C23.25 20.3438 23.25 20.4844 23.25 20.625C23.25 22.5 24.75 24 26.625 24C28.4531 24 30 22.5 30 20.625C30 18.7969 28.4531 17.25 26.625 17.25ZM10.875 4.5C10.2188 4.5 9.75 4.03125 9.75 3.375C9.75 2.76562 10.2188 2.25 10.875 2.25C11.4844 2.25 12 2.76562 12 3.375C12 4.03125 11.4844 4.5 10.875 4.5ZM15.75 17.25C14.0625 17.25 12.75 15.9375 12.75 14.25C12.75 12.6094 14.0625 11.25 15.75 11.25C17.3906 11.25 18.75 12.6094 18.75 14.25C18.75 15.9375 17.3906 17.25 15.75 17.25ZM26.625 21.75C25.9688 21.75 25.5 21.2812 25.5 20.625C25.5 20.0156 25.9688 19.5 26.625 19.5C27.2344 19.5 27.75 20.0156 27.75 20.625C27.75 21.2812 27.2344 21.75 26.625 21.75ZM24.0469 9.51562L23.1562 8.34375L20.9062 10.0312L21.7969 11.2031L24.0469 9.51562ZM27 9C28.6406 9 30 7.6875 30 6C30 4.35938 28.6406 3 27 3C25.3125 3 24 4.35938 24 6C24 7.6875 25.3125 9 27 9ZM7.125 15H9.375V13.5H7.125V15ZM3 11.25C1.3125 11.25 0 12.6094 0 14.25C0 15.9375 1.3125 17.25 3 17.25C4.64062 17.25 6 15.9375 6 14.25C6 12.6094 4.64062 11.25 3 11.25Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: "Interactivity",
  props: {
    width: {
      type: Number,
      default: 20,
    },
    height: {
      type: Number,
      default: 18,
    },
    color: String,
  },
};
</script>

<style></style>
