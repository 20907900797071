<template>
  <svg viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      class="rotate"
      d="M77.8725 24.2916L57.019 3.44016L55.8393 2.26047C55.4703 1.89106 55.065 1.55996 54.6293 1.27224C53.9978 0.854926 53.3077 0.533751 52.5817 0.319233C52.3433 0.24806 52.1024 0.18875 51.859 0.141302C50.3903 -0.143203 48.8708 0.00388642 47.484 0.564779C47.252 0.659676 47.0238 0.765606 46.7997 0.882566C46.1115 1.24458 45.4821 1.70867 44.9328 2.25905L43.7499 3.44158L22.9017 24.2902C22.1855 25.0063 21.6175 25.8564 21.2299 26.7921C20.8423 27.7278 20.6428 28.7306 20.6428 29.7434C20.6428 30.7562 20.8423 31.759 21.2299 32.6947C21.6175 33.6303 22.1855 34.4805 22.9017 35.1966L43.7552 56.0502L44.9349 57.2299C46.0074 58.3016 47.3716 59.0337 48.8575 59.3349C50.3434 59.6362 51.8851 59.4932 53.2902 58.9238C53.5223 58.8289 53.7504 58.7231 53.9746 58.6064C54.6627 58.2443 55.2921 57.7803 55.8414 57.2299L57.0243 56.047L77.8725 35.1984C78.5887 34.4823 79.1568 33.6321 79.5445 32.6964C79.9321 31.7607 80.1316 30.7578 80.1316 29.745C80.1316 28.7322 79.9321 27.7293 79.5445 26.7936C79.1568 25.8579 78.5887 25.0077 77.8725 24.2916Z"
      fill="#214B61"
    />
    <path
      class="move"
      d="M96.1736 42.7516L94.5078 41.0356L61.7364 73.807L59.7215 75.8216C58.786 76.7591 57.7139 77.5497 56.5418 78.1664C56.1606 78.3666 55.772 78.5469 55.376 78.7073C52.9826 79.6765 50.3567 79.9197 47.8259 79.4066C45.2951 78.8935 42.9714 77.6468 41.1444 75.8219L39.1352 73.8124L6.25876 40.936L4.6307 42.5851C2.24824 44.996 0.915112 48.2507 0.921778 51.6402C0.928443 55.0297 2.27437 58.2792 4.66629 60.6807C16.8012 72.8658 36.9544 93.0443 36.9544 93.0443L39.3408 95.4307C41.5108 97.5982 44.2709 99.079 47.2768 99.6884C50.2827 100.298 53.4015 100.009 56.2443 98.858C56.7136 98.6682 57.175 98.454 57.6286 98.2153C59.0207 97.483 60.294 96.5441 61.405 95.4307L63.7975 93.0379L96.0387 60.7967C98.4262 58.4092 99.7787 55.1782 99.8039 51.8018C99.8292 48.4254 98.5252 45.1746 96.1736 42.7516Z"
      fill="#FF5B01"
    />
  </svg>
</template>

<script>
export default {
  name: "LoadingIcon",
};
</script>

<style lang="scss" scoped>
.rotate {
  transform-origin: 50% 30%;
  animation: ckw 2s infinite;
  /* <--- */
}
.move {
  animation: MoveUpDown 2s linear infinite;
  animation-delay: 1s;
  position: absolute;
  left: 0;
  bottom: 0;
}

@keyframes MoveUpDown {
  0%,
  20% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, -10%);
  }
  70%,
  100% {
    transform: translate(0, 0);
  }
}

@keyframes ckw {
  0%,
  20% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  70%,
  100% {
    transform: rotate(0deg);
  }
}
</style>
